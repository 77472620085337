/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/globals.css";
import "@fontsource/mr-dafoe/400.css";
import "@fontsource/permanent-marker";
import React from 'react';
import { RootWrapper } from './src/components/RootWrapper';

export const wrapRootElement = ({ element }) => {
  return <RootWrapper>{element}</RootWrapper>;
};
