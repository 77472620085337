import React, { useEffect } from 'react';

const LoadingSpinner = () => (
  <div className="inline-block mr-2">
    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
);

const Toast = ({ message, type = 'success', onClose, duration = 3000 }) => {
  useEffect(() => {
    // Don't auto-close if it's a loading toast
    if (type !== 'loading') {
      const timer = setTimeout(() => {
        onClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [duration, onClose, type]);

  const baseStyles = "fixed bottom-4 right-4 p-4 rounded-lg shadow-lg transform transition-transform duration-300 ease-in-out flex items-center";
  const typeStyles = {
    success: "bg-green-500 text-white",
    error: "bg-red-500 text-white",
    loading: "bg-blue-500 text-white"
  };

  return (
    <div className={`${baseStyles} ${typeStyles[type]}`}>
      {type === 'loading' && <LoadingSpinner />}
      {message}
    </div>
  );
};

export default Toast; 